import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import * as functions from '../functions';

/**
 * observable for keeping logged in user's balance
 */
export const balance$ = new BehaviorSubject(0);

/**
 * Let's have a BehaviorSubject of bets selected by the user
 * Choose BehaviorSubject because it emits current value
 * or last emitted value to new subscribers
 *
 */
export const bets$ = new BehaviorSubject([]);

/**
 * observable for keeping games
 */
export const games$ = new BehaviorSubject([]);

/**
 * pipe a transformation to get only Ids
 */
export const gameIds$ = bets$.pipe(
  map((games) => {
    const keys = {};
    for (const { matchId } of games) {
      keys[matchId] = matchId;
    }
    return keys;
  })
);

/**
 * Initial Loader
 */
export const initialLoader$ = new BehaviorSubject(true);

/**
 * observable for keeping Live games
 */
export const liveGames$ = new BehaviorSubject([]);
export const searchKeyWord$ = new BehaviorSubject('');
export const selectedMarket$ = new BehaviorSubject('');

/**
 * League Games
 */
export const leagueGames$ = games$.pipe(

  map((games) => {
    if (games.length === 0) return {};

    const gamesGroupedByLeague = {};
    for (const game of games) {
      const { League: league } = game;

      if (league in gamesGroupedByLeague) {
        // check if game is already in the array
        const index = gamesGroupedByLeague[league].findIndex(
          (item) => item.OriginalMatchId === game.OriginalMatchId
        );

        if (index === -1) {
          // add it
          gamesGroupedByLeague[league].push(game);
        } else {
          // replace it
          gamesGroupedByLeague[league].splice(index, 1, game);
        }
      } else {
        gamesGroupedByLeague[league] = [game];
      }
    }

    return gamesGroupedByLeague;
  })
);

/**
 * leagues
 */
export const leagues$ = leagueGames$.pipe(map(Object.keys));

/**
 * pipe a transformation to get keys of selected betOptions
 */
export const selectedBetOptionKeys$ = bets$.pipe(
  map((events) => {
    const keys = {};

    for (const { key } of events) {
      keys[key] = key;
    }
    return keys;
  })
);

/**
 * stake observable
 */
export const stake$ = new BehaviorSubject('');

/**
 * stake observable
 */
 export const hasLiveGames$ = new BehaviorSubject(false);


/**
 * receipt top summary
 */
export const selectionsAggregate$ = combineLatest([bets$, stake$]).pipe(
  map(([bets, stake]) => {
    return {
      selections: bets.length,
      stake,
      totalOdds: functions.computeTotalOdds(bets)
    };
  })
);

import { map } from 'rxjs/operators';
import * as functions from '../functions';
import {
  MARKET_1X2,
  MARKET_1X2_H1,
  MARKET_1X2_H2,
  MARKET_BTS,
  MARKET_BTS_H1,
  MARKET_BTS_H2,
  MARKET_DC,
  MARKET_DC_BTS,
  MARKET_DC_H1,
  MARKET_DC_H2,
  MARKET_DC_OU50,
  MARKET_DNB,
  MARKET_EH,
  MARKET_HSH,
  MARKET_HTFT,
  MARKET_OE,
  MARKET_OU,
  MARKET_OU_H1,
  MARKET_OU_H2,
  MARKET_RBTS,
  MARKET_RTG,
  MARKET_TGBTS
} from '../markets';

export const computeTotalOdds = (bets) =>
  bets.length === 0 ? 0 : bets.reduce((a, c) => a * c.odd, 1);

const FULL_TIME_HALF_TIME_AWAY_WIN_REGEX = /^\/{0,2}?2$/;
const FULL_TIME_HALF_TIME_DRAW_REGEX = /^\/{0,2}?0$/;
const FULL_TIME_HALF_TIME_HOME_WIN_REGEX = /^\/{0,2}?1$/;
const FULL_TIME_OVER_REGEX = /^[0-5]\.5\+$/;
const FULL_TIME_UNDER_REGEX = /^[0-5]\.5-$/;
const HALF_TIME_OVER_REGEX = /^\/{1,2}[0-5]\.5\+$/;
const HALF_TIME_UNDER_REGEX = /^\/{1,2}[0-5]\.5-$/;
const DOUBLE_CHANCE_HOME_DRAW_REGEX = /^\/{0,2}?10$/;
const DOUBLE_CHANCE_HOME_AWAY_REGEX = /^\/{0,2}?12$/;
const DOUBLE_CHANCE_DRAW_AWAY_REGEX = /^\/{0,2}?02$/;
const BOTH_TEAMS_TO_SCORE_REGEX = /^\/{0,2}?\+{2}$/;
const BOTH_TEAMS_NOT_TO_SCORE_REGEX = /^\/{0,2}?\+-$/;

export const evaluateBetOptionShortCut = (localShortcut) => {
  // FullTime 1x2 (0, 1, 2)
  // HalfTime 1x2 (/0, /1, /2)
  // 2nd HalfTime (//0, //1, //2)
  if (FULL_TIME_HALF_TIME_DRAW_REGEX.test(localShortcut)) return 'X'; //FT1X2: X
  if (FULL_TIME_HALF_TIME_HOME_WIN_REGEX.test(localShortcut)) return '1'; //FT1X2: 1
  if (FULL_TIME_HALF_TIME_AWAY_WIN_REGEX.test(localShortcut)) return '2'; //FT1X2: 2

  // FullTime Under/Over (0.5 - 5.5)
  if (FULL_TIME_OVER_REGEX.test(localShortcut)) return 'Over';
  if (FULL_TIME_UNDER_REGEX.test(localShortcut)) return 'Under';

  // Halftime Under/Over (/0.5 - /2.5)
  // 2nd HalfTime Under/Over (//0.5 - //2.5)
  if (HALF_TIME_OVER_REGEX.test(localShortcut)) return 'Over';
  if (HALF_TIME_UNDER_REGEX.test(localShortcut)) return 'Under';

  // Double Chance (10, 12, 02)
  // 1st Half Double Chance (/10, /12, /02)
  // 2nd Half Double Chance (//10, //12, //02)
  if (DOUBLE_CHANCE_HOME_DRAW_REGEX.test(localShortcut)) return '1X';
  if (DOUBLE_CHANCE_HOME_AWAY_REGEX.test(localShortcut)) return '12';
  if (DOUBLE_CHANCE_DRAW_AWAY_REGEX.test(localShortcut)) return 'X2';

  //HalfTime Full Time
  if (localShortcut === '1/1') return '1/1'; //HTFT: 1/1
  if (localShortcut === '1/0') return '1/X'; //HTFT: 1/X
  if (localShortcut === '1/2') return '1/2'; //HTFT: 1/2
  if (localShortcut === '0/1') return 'X/1'; //HTFT: X/1
  if (localShortcut === '0/0') return 'X/X'; //HTFT: X/X
  if (localShortcut === '0/2') return 'X/2'; //HTFT: X/2
  if (localShortcut === '2/1') return '2/1'; //HTFT: 2/1
  if (localShortcut === '2/0') return '2/X'; //HTFT: 2/X
  if (localShortcut === '2/2') return '2/2'; //HTFT: 2/2

  // Both Team To Score (++, +-)
  // Half Time - Both Team To Score (/++, /+-)
  // 2nd Half Time - Both Team To Score (//++, //+-)
  if (BOTH_TEAMS_TO_SCORE_REGEX.test(localShortcut)) return 'Yes';
  if (BOTH_TEAMS_NOT_TO_SCORE_REGEX.test(localShortcut)) return 'No';

  // Handicap
  if (localShortcut === '1.') return '1'; //European Handicap 0:1: 1
  if (localShortcut === '0.') return 'X'; //European Handicap 0:1: X
  if (localShortcut === '2.') return '2'; //European Handicap 0:1: 2

  // Draw No Bet
  if (localShortcut === '10.') return '1'; //Draw No Bet: 1
  if (localShortcut === '20.') return '2'; //Draw No Bet: 2

  // Highest Scoring Half
  if (localShortcut === '1+') return '1st Half'; //1st Half
  if (localShortcut === '0+') return 'X'; // X
  if (localShortcut === '2+') return '2nd Half'; //2nd Half

  // Odd Even
  if (localShortcut === '+') return 'Even'; //Total Goals: Even
  if (localShortcut === '-') return 'Odd'; //Total Goals: Odd

  // FT & 2.5 Under/Over
  if (localShortcut === '1/2.5-') return '1/u'; // return  option names from db
  if (localShortcut === '1/2.5+') return '1/o';
  if (localShortcut === '0/2.5-') return 'x/u';
  if (localShortcut === '0/2.5+') return 'x/o';
  if (localShortcut === '2/2.5+') return '2/o';
  if (localShortcut === '2/2.5-') return '2/u';

  // FT & BTS
  if (localShortcut === '1/++') return '1/yes'; //wrong
  if (localShortcut === '1/+-') return '1/no';
  if (localShortcut === '0/++') return 'x/yes';
  if (localShortcut === '0/+-') return 'x/no';
  if (localShortcut === '2/++') return '2/yes';
  if (localShortcut === '2/+-') return '2/no';

  // Double Chance & BTS
  if (localShortcut === '10/++') return '1X/yes';
  if (localShortcut === '10/+-') return '1X/no';
  if (localShortcut === '12/++') return '12/yes';
  if (localShortcut === '12/+-') return '12/no';
  if (localShortcut === '02/++') return 'X2/yes';
  if (localShortcut === '02/+-') return 'X2/no';

  // Double Chance & 2.5 Under/Over
  if (localShortcut === '10/2.5+') return '1X/o';
  if (localShortcut === '10/2.5-') return '1X/u';
  if (localShortcut === '12/2.5+') return '12/o';
  if (localShortcut === '12/2.5-') return '12/u';
  if (localShortcut === '02/2.5+') return 'X2/o';
  if (localShortcut === '02/2.5-') return 'X2/u';

  // 2.5 Under/Over & BTS
  if (localShortcut === '2.5+/++') return 'o/yes';
  if (localShortcut === '2.5-/++') return 'u/yes';
  if (localShortcut === '2.5+/+-') return 'o/no';
  if (localShortcut === '2.5-/+-') return 'u/no';

  return null;
};

export const evaluateBetOptionShortCutCategory = (localShortcut) => {
  if (/^[0-2]$/.test(localShortcut)) return MARKET_1X2; // full time wire
  if (/^[0-5]\.5[+-]$/.test(localShortcut)) return MARKET_OU; // full time - under/over
  if (/^\/[0-2]$/.test(localShortcut)) return MARKET_1X2_H1; // half time wire
  if (/^\/{2}[0-2]$/.test(localShortcut)) return MARKET_1X2_H2; // 2nd half time wire
  if (/^\/[0-2]\.5[+-]$/.test(localShortcut)) return MARKET_OU_H1; // over/under half time
  if (/^\/{2}[0-2]\.5[+-]$/.test(localShortcut)) return MARKET_OU_H2; // over under 2nd half
  if (/^(10|12|02)$/.test(localShortcut)) return MARKET_DC; // double chance
  if (/^\/(10|12|02)$/.test(localShortcut)) return MARKET_DC_H1; // 1st half double chance
  if (/^\/{2}(10|12|02)$/.test(localShortcut)) return MARKET_DC_H2; // 2nd half double chance
  if (/^[0-2]\/[0-2]$/.test(localShortcut)) return MARKET_HTFT; // half time full time
  if (/^\+[+-]$/.test(localShortcut)) return MARKET_BTS; // both teams to score
  if (/^\/\+[+-]$/.test(localShortcut)) return MARKET_BTS_H1; // both teams to score - half time
  if (/^\/{2}\+[+-]$/.test(localShortcut)) return MARKET_BTS_H2; // both teams to score - 2nd half time
  if (/^[12]0\.$/.test(localShortcut)) return MARKET_DNB; // draw no bet
  if (/^[012]\.$/.test(localShortcut)) return MARKET_EH; // european handicap
  if (/^[012]\+$/.test(localShortcut)) return MARKET_HSH; // high scoring half
  if (/^[+-]$/.test(localShortcut)) return MARKET_OE; // odd even
  if (/^[012]\/2\.5[+-]$/.test(localShortcut)) return MARKET_RTG; // full time and 2.5 under/over
  if (/^[012]\/\+[+-]$/.test(localShortcut)) return MARKET_RBTS; // full time and both teams to score
  if (/^[012]{2}\/\+[+-]$/.test(localShortcut)) return MARKET_DC_BTS; // double chance and both teams to score
  if (/^[012]{2}\/2\.5[+-]$/.test(localShortcut)) return MARKET_DC_OU50; // double chance and under/over 2.5
  if (/^2\.5[+-]\/\+[+-]$/.test(localShortcut)) return MARKET_TGBTS; // under/over 2.5 and both teams to score
};

export const filterGamesBySearchQuery = (games, searchQuery) => {
  const result = {};

  for (const league in games) {
    for (const game of games[league]) {
      if (game.HomeTeamName.includes(searchQuery) ||
        game.AwayTeamName.includes(searchQuery)) {
        // check if the league exists in the hash
        if ((league in result) === false) result[league] = [];

        // prepend
        result[league].push(game);
      }
    }
  }

  return result;
};

export const findBetOption = (
  game,
  betOptionFromShortCut,
  category,
  shortcut
) => {
  if (['DC_OU50', 'OU', 'OU_H1', 'OU_H2', 'RTG', 'TGBTS'].includes(category)) {
    let line;
    switch (category) {
      case 'OU_H1':
        line = parseFloat(shortcut.replace('/', ''));
        break;
      case 'OU_H2':
        line = parseFloat(shortcut.replace('//', ''));
        break;
      case 'DC_OU50':
      case 'RTG':
        line = parseFloat(shortcut.substring(shortcut.indexOf('/') + 1));
        break;
      case 'TGBTS':
        line = shortcut.substring(0, shortcut.indexOf('/'));
        line = '2.5';
        break;
      default:
        line = parseFloat(shortcut);
        break;
    }
    return getLineBetOptionInGameOdds(
      game.MatchOdds,
      betOptionFromShortCut,
      `${line}`,
      category
    );
  }

  if (['EH', 'EH_H1'].includes(category)) {
    return getHandicapBetOptionInGameOdds(
      game.MatchOdds,
      betOptionFromShortCut,
      category
    );
  }

  return getLineBetOptionInGameOdds(
    game.MatchOdds,
    betOptionFromShortCut,
    null,
    category
  );
};

/*
 * Find a game from an ordered set of games (using binary search)
 */
export const findGame = (games, matchNumber) => {
  const localMatchNumber =
    typeof matchNumber === 'string' ? parseInt(matchNumber, 10) : matchNumber;

  return games.find((x) => x.MatchNo === localMatchNumber);
};

export const getFormattedMoney = (amount, currency, locale) =>
  new Intl.NumberFormat(locale, { currency, style: 'currency' }).format(amount);

export const getHandicapBetOptionInGameOdds = (gameOdds, betOption, market) => {
  let awayOdd, homeOdd, line;

  homeOdd = gameOdds.find(
    (v) => v.BetOption === '1' && v.BetCategory === '1x2'
  ).Odd;
  awayOdd = gameOdds.find(
    (v) => v.BetOption === '2' && v.BetCategory === '1x2'
  ).Odd;

  if (homeOdd > awayOdd) {
    line = homeOdd > 1.3 ? 1 : 2;
  } else if (homeOdd < awayOdd) {
    line = homeOdd > 1.3 ? -1 : -2;
  }

  return gameOdds.find(
    (v) =>
      v.BetOption === betOption &&
      parseFloat(v.Line) === line &&
      v.BetCategory === market
  );
};

export const getLineBetOptionInGameOdds = (odds, betOption, line, market) =>
  odds.find(
    (v) =>
      v.BetOption === betOption && v.Line === line && v.BetCategory === market
  );

export const getReceiptStatusName = (() => {
  const memo = new Map();
  memo.set(-1, 'Cancelled');
  memo.set(1, 'Pending');
  memo.set(2, 'Lost');
  memo.set(3, 'Won');
  memo.set(4, 'Paid');

  return (intReceiptStatus) => memo.get(intReceiptStatus) ?? 'Undefined';
})();

export const getSelectedBetName = (() => {
  // create a memo for selections
  const memo = new Map();

  return (market, option, line = null) => {
    const key = [market, option, line ?? ''].join(',');

    if (memo.has(key)) return memo.get(key);

    switch (market) {
      case MARKET_1X2: {
        memo.set(key, `FT1X2: ${option}`);
        return memo.get(key);
      }
      case MARKET_1X2_H1: {
        memo.set(key, `HT1X2: ${option}`);
        return memo.get(key);
      }
      case MARKET_1X2_H2: {
        map.set(key, `2HT1X2: ${option}`);
        return memo.get(key);
      }
      case MARKET_BTS: {
        memo.set(key, `Both Teams To Score: ${option}`);
        return memo.get(key);
      }
      case MARKET_BTS_H1: {
        memo.set(key, `HT Both Teams To Score: ${option}`);
        return memo.get(key);
      }
      case MARKET_BTS_H2: {
        memo.set(key, `2HT Both Teams To Score: ${option}`);
        return memo.get(key);
      }
      case MARKET_DC: {
        memo.set(key, `Double Chance: ${option}`);
        return memo.get(key);
      }
      case MARKET_DC_BTS: {
        switch (option) {
          case '1X/yes':
            memo.set(key, '1X & Yes');
            return memo.get(key);
          case '1x/no':
            memo.set(key, '1X & No');
            return memo.get(key);
          case '12/yes':
            memo.set(key, '12 & Yes');
            return memo.get(key);
          case '12/no':
            memo.set(key, '12 & No');
            return memo.get(key);
          case 'X2/yes':
            memo.set(key, 'X2 & Yes');
            return memo.get(key);
          case 'X2/no':
            memo.set(key, 'X2 & No');
            return memo.get(key);
          default:
            throw new Error('Unknown option for market DC_BTS');
        }
      }
      case MARKET_DC_H1: {
        memo.set(key, `HT Double Chance: ${option}`);
        return memo.get(key);
      }
      case MARKET_DC_H2: {
        memo.set(key, `2HT Double Chance: ${option}`);
        return memo.get(key);
      }
      case MARKET_DC_OU50: {
        const [first, last] = option.split('/');

        const stdOptionNameFn = (f1) => {
          let partialResult = f1.toUpperCase();
          return (f2, ln) =>
            partialResult + (f2 === 'u' ? ` & Under ${ln}` : ` & Over ${ln}`);
        };

        memo.set(key, stdOptionNameFn(first)(last, line));
        return memo.get(key);
      }
      case MARKET_DNB: {
        memo.set(key, `Draw No Bet: ${option}`);
        return memo.get(key);
      }
      case MARKET_EH: {
        const goals = line < 0 ? `0:${line * -1}` : `${line}:0`;
        memo.set(key, `Handicap ${goals}: ${option}`);
        return memo.get(key);
      }
      case MARKET_HSH: {
        memo.set(key, `Half With Most Goals: ${option}`);
        return memo.get(key);
      }
      case MARKET_HTFT: {
        switch (option) {
          case '1/1':
            memo.set(key, 'HalfTime FullTime: 1/1');
            return memo.get(key);
          case '1/X':
            memo.set(key, 'HalfTime FullTime: 1/X');
            return memo.get(key);
          case '1/2':
            memo.set(key, 'HalfTime FullTime: 1/2');
            return memo.get(key);
          case 'X/1':
            memo.set(key, 'HalfTime FullTime: X/1');
            return memo.get(key);
          case 'X/X':
            memo.set(key, 'HalfTime FullTime: X/X');
            return memo.get(key);
          case 'X/2':
            memo.set(key, 'HalfTime FullTime: X/2');
            return memo.get(key);
          case '2/1':
            memo.set(key, 'HalfTime FullTime: 2/1');
            return memo.get(key);
          case '2/X':
            memo.set(key, 'HalfTime FullTime: 2/X');
            return memo.get(key);
          case '2/2':
            memo.set(key, 'HalfTime FullTime: 2/2');
            return memo.get(key);
          default:
            throw new Error('Unknown option for HalfTime FullTime result');
        }
      }
      case MARKET_OE: {
        memo.set(key, `Total Goals: ${option}`);
        return memo.get(key);
      }
      case MARKET_OU: {
        memo.set(key, `FT Under/Over ${line}: ${option}`);
        return memo.get(key);
      }
      case MARKET_OU_H1: {
        memo.set(key, `HT Under/Over ${line}: ${option}`);
        return memo.get(key);
      }
      case MARKET_OU_H2: {
        memo.set(key, `2HT Under/Over ${line}: ${option}`);
        return memo.get(key);
      }
      case MARKET_RBTS: {
        const [first, last] = option.split('/');
        const stdOptionNameFn = (f1) => {
          let partialResult =
            f1 === '1' ? 'Home Win' : f1 === 'x' ? 'Draw' : 'Away Win';
          return (f2) => partialResult + (f2 === 'yes' ? ` & Yes` : ` & No`);
        };

        memo.set(key, stdOptionNameFn(first)(last));
        return memo.get(key);
      }
      case MARKET_RTG: {
        const [first, last] = option.split('/');

        const stdOptionNameFn = (f1) => {
          let partialResult =
            f1 === '1' ? 'Home Win' : f1 === 'x' ? 'Draw' : 'Away Win';
          return (f2, ln) =>
            partialResult + (f2 === 'u' ? ` & Under ${ln}` : ` & Over ${ln}`);
        };

        memo.set(key, stdOptionNameFn(first)(last, line));
        return memo.get(key);
      }
      case MARKET_TGBTS: {
        const [first, last] = option.split('/');
        memo.set(
          key,
          `${/o/i.test(first) ? 'Over' : 'Under'} ${line} & ${
            /yes/i.test(last) ? 'Yes' : 'No'
          }`
        );
        return memo.get(key);
      }
      default:
        throw new Error('Unknown market');
    }
  };
})();

export const isArray = (obj) => obj && Array.isArray(obj);

export const isString = (obj) => toString.call(obj) === '[object String]';

/**
 * returns true if the NODE_ENV is development
 */
export const isDevelopmentEnvironment = () =>
  process.env.NODE_ENV.match(/development/i);

/**
 * returns true if the NODE_ENV is development
 */
export const isProductionEnvironment = () =>
  process.env.NODE_ENV.match(/production/i);

/**
 * @param {description} log description
 * @returns void
 */
export const logIfDebug = (description) => (value) => {
  if (functions.isDevelopmentEnvironment()) {
    console.log(description);
    console.log(value);
  }
};

/**
 * update changed odd in a game
 */
export const updateChangedOdd = (games$, bet) => {
  const games = games$.value;
  for (const game of games) {
    if (game.OriginalMatchId === bet.matchId) {
      const betOption = game.MatchOdds.find(function(mo) {
        return (
          mo.BetCategory === bet.market &&
          mo.BetOption === bet.option &&
          mo.BookMakerId === bet.bookMakerId &&
          mo.Line === bet.line
        );
      });

      if (betOption) {
        betOption.Odd = bet.Odd;
        games$.next(games);
      }

      break;
    }
  }
};

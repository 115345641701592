import { interval } from 'rxjs';
import { startWith } from 'rxjs/operators';
import * as constants from './constants';
//import initializeSocketIO from './socket-io';
import socket from "./socket-io";
import './index.scss';

// check for games every 15 minutes - will be converted to something appropriate
const useRefresh = process.env.useRefresh || true;
if (useRefresh) {
  const refreshRate = interval(15 * 60 * 1000);
  const subscription = refreshRate.pipe(startWith(0)).subscribe({
    complete: () => subscription.unsubscribe(),
    error: (error) => console.error(error),
    next: async () => {
      const { getGames } = await import('./api');
      const { isArray } = await import('./functions');
      const { games$ } = await import('./rxjs-stores');

      try {
        const { data: games } = await getGames();

        if (isArray(games) && games.length > 0) {
          games$.next(games);
          return;
        }
      } catch (e) {
        console.log(e);
      }
      games$.next([]);
    }
  });
}

// render the application
const renderApp = async () => {
  const { store } = await import('./redux');
  const { render } = await import('react-dom');
  const { Provider } = await import('react-redux');
  const { ToastProvider } = await import('react-toast-notifications');
  const { BrowserRouter } = await import('react-router-dom');
  const { default: React } = await import('react');
  const { App } = await import('./components');

  render(
    <React.StrictMode>
      <Provider store={store}>
        <ToastProvider autoDismiss={true} placement="top-center">
          <BrowserRouter forceRefresh={false}>
            <App />
          </BrowserRouter>
        </ToastProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

// set up online client information
const setUpOnlineClientInformation = async () => {
  const { getOnlineClientInformation } = await import('./async-actions');
  const { store } = await import('./redux');
  store.dispatch(getOnlineClientInformation());
};
const setUpOnlineSettings = async () => {
  const { getOnlineSettings} = await import('./async-actions');
  const { store } = await import('./redux');
  store.dispatch(getOnlineSettings());
};

// check if the user is signed in every 15 minutes
const setUpUserStatusCheck = async () => {
  const checkLoginStatusRate = interval(15 * 60 * 1000).pipe(startWith(0));
  const loginStatusSubscription = checkLoginStatusRate.subscribe({
    complete: () => loginStatusSubscription.unsubscribe(),
    error: (error) => console.error(error),
    next: async () => {
      const {
        actions: { updateLoginStatus },
        store
      } = await import('./redux');
      try {
        const { revalidateLogin } = await import('./api');
        await revalidateLogin();
        store.dispatch(updateLoginStatus(true));
      } catch (e) {
        store.dispatch(updateLoginStatus(false));
      }
    }
  });
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
const setUpWebVitals = async () => {
  const { isDevelopmentEnvironment } = await import('./functions');

  if (isDevelopmentEnvironment()) {
    const reportWebVitals = (await import('./reportWebVitals')).default;
    reportWebVitals(console.log);
  }
};

// Load online client information
document.addEventListener(constants.CUSTOM_EVENTS.DOM_CONTENT_LOADED, () => {
 // initializeSocketIO();
  renderApp();
  setUpOnlineSettings();
  setUpOnlineClientInformation();
  setUpUserStatusCheck();
  setUpWebVitals();
});
console.log("Current visibility state:", document.visibilityState);
let live_panel_visible = false;
document.addEventListener("visibilitychange", function () {
  console.log(document.visibilityState);
    if (document.visibilityState === "visible") {
        console.log("document visible - maybe navigating back");
        if (live_panel_visible) {
            socket.reconnect();
        }
    } else {
        console.log("document invisible - maybe navigating away");
        socket.disconnect();
    }
});
document.addEventListener("LiveDisplayVisibleEvent", function (evt) {
   console.log(evt);
    live_panel_visible = evt.detail.visible;
    console.log("live_panel_visible :", live_panel_visible );
    if (live_panel_visible) {
        socket.connect();
    } else {
        socket.disconnect();
    }
});

import { createSlice } from '@reduxjs/toolkit';
import { MODES } from '../constants';

const onlineClientSlice = createSlice({
  name: 'onlineClient',
  initialState: {
    loggedIn: false
  },
  reducers: {
    initializeOnlineClientInfo: (state, action) => ({
      ...action.payload,
      ...state
    }),
    updateLoginStatus: (state, action) => {
      state.loggedIn = action.payload;
    },
    updateAccounts: (state, action) => {
      state.Balance = action.payload.Balance;
    }
  }
});

const propertiesSlice = createSlice({
  name: 'properties',
  initialState: {
    mode: MODES.LIST_GAMES_MODE,
    searching: false,
    searchText: '',
    selectedGame: null
  },
  reducers: {
    updateMode: (state, action) => {
      state.mode = action.payload;
    },
    updateSearching: (state, action) => {
      state.searching = action.payload;
    },
    updateSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    updateSelectedGame: (state, action) => {
      state.selectedGame = action.payload;
    }
    
  }
});

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    Bonus: 0,
    currency: 'UGX',
    locale: 'lg-UG',
    Tax: 0,
    MaxPayOut: 0  // <-- Add this so it's part of the Redux state
  },
  reducers: {
    initializeSettings: (state, action) => {
      console.log("initializeSettings payload:", action.payload);
      return {
        ...state,
        ...action.payload
      };
    }
  }
});


const actions = {
  ...onlineClientSlice.actions,
  ...propertiesSlice.actions,
  ...settingsSlice.actions
};

export const reducers = {
  onlineClient: onlineClientSlice.reducer,
  properties: propertiesSlice.reducer,
  settings: settingsSlice.reducer
};

export default actions;

export const MARKET_1X2 = '1x2';
export const MARKET_1X2_H1 = '1x2_H1';
export const MARKET_1X2_H2 = '1X2_H2';
export const MARKET_BTS = 'BTS';
export const MARKET_BTS_H1 = 'BTS_H1';
export const MARKET_BTS_H2 = 'BTS_H2';
export const MARKET_DC = 'DC';
export const MARKET_DC_BTS = 'DC_BTS';
export const MARKET_DC_H1 = 'DC_H1';
export const MARKET_DC_H2 = 'DC_H2';
export const MARKET_DC_OU50 = 'DC_OU50';
export const MARKET_DNB = 'DNB';
export const MARKET_EH = 'EH';
export const MARKET_HSH = 'HSH';
export const MARKET_HTFT = 'HTFT';
export const MARKET_OE = 'OE';
export const MARKET_OE_H1 = 'OE_H1';
export const MARKET_OE_H2 = 'OE_H2';
export const MARKET_OU = 'OU';
export const MARKET_OU_H1 = 'OU_H1';
export const MARKET_OU_H2 = 'OU_H2';
export const MARKET_RBTS = 'RBTS';
export const MARKET_RTG = 'RTG';
export const MARKET_TGBTS = 'TGBTS';
export const MARKET_NEXTGOAL = 'S1G';

export const ONLINE_MARKETS = {
  MARKET_1X2: { label: 'Match Result', isActive: true },
  MARKET_1X2_H1: { label: 'HalfTime Result', isActive: true },
  MARKET_1X2_H2: { label: '2ndHalf Result', isActive: true },
  MARKET_DC: { label: 'Double Chance', isActive: true },
  MARKET_BTS: { label: 'Both Team To Score', isActive: true },
  MARKET_OE: { label: 'Odd/Even', isActive: true },
  MARKET_DC_H1: { label: 'HalfTime Double Chance', isActive: true },
  MARKET_DC_H2: { label: '2ndHalf Double Chance', isActive: true },
  MARKET_OU: { label: 'Under/Over', isActive: true },
  MARKET_OU_H1: { label: 'HalfTime Under/Over', isActive: true },
  MARKET_OU_H2: { label: '2ndHalf Under/Over', isActive: true },
};
export const MARKETS_OPTIONS = {
  MARKET_1X2:['1', 'X', '2'],
  MARKET_1X2_H1: ['1', 'X', '2'],
  MARKET_1X2_H2: ['1', 'X', '2'],
  MARKET_DC: ['1X', '12', 'X2'],
  MARKET_BTS: ['Yes', 'No'],
  MARKET_BTS_H1: ['Yes', 'No'],
  MARKET_BTS_H2: ['Yes', 'No'],
  MARKET_OE: ['Odd', 'Even'],
  MARKET_OE_H1: ['Odd', 'Even'],
  MARKET_OE_H2:['Odd', 'Even'],
  MARKET_OU: ['Under', 'Over'],
  MARKET_OU_H1: ['Under', 'Over'],
  MARKET_OU_H2: ['Under', 'Over'],
}
import protobuf from 'protobufjs';
import { io } from 'socket.io-client';
import { liveGamesStorage } from './rxjs-stores';

export const get_proto_root = (() => {
  let root;

  return async () => {
    if (!root) {
      const f_names = ['event', 'game', 'market', 'odd', 'scores', 'team'].map(
        (x) => '/protos/' + x + '.proto'
      );

        root = protobuf.load(f_names);
        console.log(root);
    }
    return root;
  };
})();

function toUint8Array(base64) {
  const binaryString = atob(base64);

  const uint8Array = new Uint8Array(binaryString.length);

  for (let i = 0; i < binaryString.length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i);
  }

  return uint8Array;
}

const onConnect = () => {
  console.log('connected to socket');
};

const onDisconnect = (reason) => console.log('User disconnected: ' + reason);

function onDiffReceivedHandler(message) {
  const diff = JSON.parse(message);
  //liveGamesStorage.updateGame(diff);
}

const onEventReceivedHandler = async (message) => {
  const buffer = toUint8Array(message);

  const root = await get_proto_root();

  const EventType = root.lookupType('bet720_protocol_buffers.Event');

  const event_message = EventType.decode(buffer);
  const event_pojo = EventType.toObject(event_message, {
    arrays: true,
    objects: true,
  });

  if (event_pojo.p !== 'ALL' || !event_pojo.obj) return;
  const { inf } = event_pojo.obj[0];

  if (inf && inf.indexOf('Esoccer') > -1) return;

  event_pojo.obj[0].sc = {
    CORNER: event_pojo.obj[0]['sc']['corner'],
    FREE_KICK: event_pojo.obj[0]['sc']['freeKick'],
    GOAL: event_pojo.obj[0]['sc']['goal'],
    GOAL_KICK: event_pojo.obj[0]['sc']['goalKick'],
    H1: event_pojo.obj[0]['sc']['h1'],
    OFFSIDE: event_pojo.obj[0]['sc']['offside'],
    PENALTY: event_pojo.obj[0]['sc']['penalty'],
    RED_CARD: event_pojo.obj[0]['sc']['redCard'],
    SUBSTITUTION: event_pojo.obj[0]['sc']['substitution'],
    THROW_IN: event_pojo.obj[0]['sc']['throwIn'],
    YELLOW_CARD: event_pojo.obj[0]['sc']['yellowCard'],
  };

  liveGamesStorage.addOrUpdate(event_pojo);
};

const onExpiredGamesEvent = (payload) => {
  if (!payload) return;

  if (payload.indexOf('live_game_') !== -1) {
    liveGamesStorage.removeEvent(payload.replace('live_game_', ''));
    return;
  }

  if (payload.indexOf('buffer_') !== -1) {
    liveGamesStorage.removeEvent(payload.replace('buffer_', ''));
    return;
  }
};

const socket_module = (function () {
  let socket;
  function connect_base() {
    socket = io('wss://socket.smbet.info');
    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('expired-key', onExpiredGamesEvent);
    socket.on('buffers', onEventReceivedHandler);
    socket.on('buffer-diffs', onDiffReceivedHandler);
  }

  function connect() {
    console.log('running socket connection init - from module');
    if (socket) return socket;

    connect_base();
    return socket;
  }

  function disconnect() {
    console.log('running disconnect from module');
    if (!socket) return;

    socket.disconnect();
    socket = null;
  }

  function reconnect() {
    console.log('reconnecting to socket-io from module - mannual');
    connect_base();
  }

  return {
    connect,
    disconnect,
    reconnect,
  };
})();

export default socket_module;

import { liveGames$ } from ".";
import store from "../redux/store";
import actions from "../redux/slices";

const liveGamesStorage = (() => {
    let liveGames = [];

    /**
     * add a game
     * @param {object} game
     */
    const addOrUpdate = (json) => {
      //  console.log("json");
        console.log(json);
        const game = extract_live_game(json);
        if (!game) return;

        const { matchId } = game;
        if (!matchId) return;

        const index = liveGames.findIndex((x) => x.matchId === matchId);
        if (index > -1) {
            liveGames[index] = game;
        } else {
            liveGames.push(game);
        }

        liveGames$.next(liveGames);

        // I do not know what the code below does - I am not sure it's in the right place
        // Who cares, I do not care. Go to HELL GEYENA
        const {
            bets,
            properties: { hasLiveGames },
        } = store.getState();
        if (!hasLiveGames) return;

        const betEvent = bets.findIndex(
            (x) => x.game.OriginalMatchId === game.matchId
        );

        if (betEvent > -1) {
            if (
                game.odds === null ||
                game.odds === "undefined" ||
                game.blocked === 1
            ) {
                store.dispatch(actions.removeBet(game.matchId));
            }
            var currentOdd =
                bets[betEvent].option.Line !== null
                    ? game.odds.filter(
                          ({ n, h }) =>
                              n === bets[betEvent].option.BetCategory &&
                              h === bets[betEvent].option.Line
                      )
                    : game.odds.filter(
                          ({ n }) => n === bets[betEvent].option.BetCategory
                      );

            if (currentOdd) {
                var selectedOption = currentOdd[0].o;
                var selectedOdd = selectedOption.find(
                    (o) => o.n === bets[betEvent].option.BetOption
                );
                if (selectedOdd.v === bets[betEvent].option.Odd) {
                } else {
                    const liveBetOption = {
                        BetCategory: bets[betEvent].option.BetCategory,
                        BetOption: bets[betEvent].option.BetOption,
                        Line: bets[betEvent].option.Line,
                        Odd: selectedOdd.v,
                    };

                    store.dispatch(
                        actions.updateBet({
                            matchNo: game.shortCode,
                            option: liveBetOption,
                        })
                    );
                }
            }
        }
    };

    const getMatch = (shortCode) => {
        const code =
            typeof shortCode !== "number" ? Number(shortCode) : shortCode;
        const game = liveGames.find((x) => x.shortCode === code);
        return game;
    };

    const convert_timestamp_to_date = function (ts) {
        let ms;

        if (typeof ts === "number") {
            ms = ts * 1000;
        } else {
            ms = ts.seconds * 1000;
            ms += Math.floor(ts.nanos / 1000000);
        }
        const date = new Date(ms);
        console.log(date);
        return date.toISOString().slice(0, 19);
    };

    /**
     *
     * @param {JSON} json
     * @returns {Object|undefined}
     */
    const extract_diff_json = (json) => {
        console.log(json);
        if (!json.std) return;

        const diff = { matchId: json.std };
        console.log("xx" + convert_timestamp_to_date(json.d));
        "b" in json && (diff["blocked"] = json["b"]);
        "stn" in json && json.stn && (diff["eventState"] = json.stn);
        "st" in json && json.st && (diff["eventStateId"] = json.st);
        "inf" in json && (diff["league"] = json.inf);
        "tss" in json && json.tss && (diff["matchTime"] = json.tss);
        "tm" in json && json.tm && (diff["matchTimeSeconds"] = json.tm);
        "bt" in json && (diff["odds"] = json.bt);
        "p" in json && (diff["period"] = json.p);
        "cd" in json && (diff["shortCode"] = json.cd);
        "d" in json &&
            json.d &&
            (diff["startTime"] = convert_timestamp_to_date(json.d));

        return diff;
    };

    const extract_live_game = (json) => {
        console.log(json);
        const gameJson = json.obj[0];

        if (!gameJson.std) return undefined;
        console.log(convert_timestamp_to_date(gameJson.d));
        return {
            awayTeam: gameJson.t2.n,
            bookMakerId: gameJson.bm,
            homeTeam: gameJson.t1.n,
            matchId: gameJson.std,
            odds: gameJson.bt,
            period: gameJson.p,
            scores: gameJson.sc,
            matchTime: gameJson.tss,
            matchTimeSeconds: gameJson.tm,
            blocked: gameJson.b,
            eventState: gameJson.stn,
            eventStateId: gameJson.st,
            league: gameJson.inf,
            startTime: convert_timestamp_to_date(gameJson.d),
            shortCode: gameJson.cd,
        };
    };

    const removeEvent = function (eventId) {
        liveGames = liveGames.filter((x) => x.matchId !== eventId);
        liveGames$.next(liveGames);
    };

    const updateGame = function (json) {
        const diff = extract_diff_json(json);

    const id = json.std;
    const index = liveGames.findIndex((x) => x.matchId === id);

        if (index === -1) return;
        const game = liveGames[index];
  
        // create a new game object without scores
        // handle markets here because we replace all odds
        const new_game = { ...game, ...diff };
       // console.log(new_game);
        if (json.sc) {
            // merge scores since the diff mostly contains only updates
            new_game.scores = { ...new_game.scores, ...json.sc };
        }

        liveGames = liveGames.map((x) => (x.matchId === id ? new_game : x));
        liveGames$.next(liveGames);
    };

    return {
        addOrUpdate,
        getMatch,
        removeEvent,
        updateGame,
    };
})();

export default liveGamesStorage;

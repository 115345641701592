import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { reducers } from './slices';
import * as functions from '../functions';

const rootReducer = combineReducers(reducers);
let store;

if (functions.isProductionEnvironment()) {
  store = configureStore({
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: true
      }),
    reducer: rootReducer
  });
} else {
  store = configureStore(
    {
      middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          immutableCheck: false,
          serializableCheck: false,
          thunk: true
        }),
      reducer: rootReducer
    },
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
}

export default store;
